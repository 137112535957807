<template>
  <article class="card card--small">
    <div
      v-if="!hideStarred"
      class="starWrapper"
      :class="{ starred: isStarred }"
      @click="handleClick"
    >
      <font-awesome-icon :icon="faStar" />
    </div>
    <div class="card__body font-centered">
      <h3 class="card__title text-upper w-extrabold">
        {{ formNameRoute.split('-').join(' ') }}
      </h3>
      <router-link :to="{ name: formNameRoute }">
        <a>VER LISTA</a>
      </router-link>
    </div>
  </article>
</template>

<script>
import { toRefs, computed } from 'vue'
import { useStore } from 'vuex'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'

export default {
  components: {
    FontAwesomeIcon,
  },
  props: {
    formNameRoute: String,
    hideStarred: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { formNameRoute } = toRefs(props)
    const store = useStore()

    // computed
    const isStarred = computed(() => store.state.starredForms['form'].includes(formNameRoute.value))

    //Methods
    function handleClick() {
      store.dispatch('toggleStarredForm', { type: 'form', name: formNameRoute.value })
    }

    return {
      faStar,
      handleClick,
      isStarred,
    }
  },
}
</script>

<style lang="scss" scoped>
.card {
  position: relative;
}

.card__body {
  padding: 1rem;
  background: #f3f3f3;
  border: 1px solid #cccccc;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
}

.card__title {
  font-family: Avenir;
  font-size: 1rem;
  font-weight: 800;
  line-height: 19px;
  margin-bottom: 0.5rem;
}

p {
  font-style: normal;
  font-weight: 500;
  font-size: 2rem;
  line-height: 44px;
  color: #333333;
  margin: 0;
}

a {
  font-size: 0.85rem;
  text-decoration: underline;
}

.in-between {
  margin-bottom: 2rem;
}

.starWrapper {
  position: absolute;
  background: transparent;
  cursor: pointer;

  svg {
    color: #dddddd;
  }

  &.starred {
    svg {
      color: #e8b407 !important;
    }

    &:hover {
      svg {
        color: #9e7900 !important;
      }
    }
  }

  &:hover {
    svg {
      color: #686868 !important;
    }
  }
}
</style>