<template>
  <main>
    <section class="horizon">
      <div v-if="route.name == 'Formularios'" class="container">
        <div class="in-between">
          <h2 class="horizon__title">
            Formularios
          </h2>
          <div class="upper-date--container">
            <PeriodoReporte :show-p-anterior="false" :show-anno-anterior="false" />
          </div>
        </div>

        <div class="gr-12 card--group">
          <div class="row">
            <template v-for="(nameFormRoute, idx) in listOfFormsRoutes" :key="idx">
              <CardFormulario :form-name-route="nameFormRoute" :hide-starred="false" />
            </template>
          </div>
        </div>
      </div>

      <div class="container">
        <router-view />
      </div>
    </section>
  </main>
</template>

<script>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import PeriodoReporte from '@/components/PeriodoReporte.vue'
import CardFormulario from '@/components/CardFormulario.vue'

export default {
  components: {
    PeriodoReporte,
    CardFormulario,
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const periodoActualDesde = ref(store.getters.getPeriodoReporte[0]['desde'])
    const periodoActualHasta = ref(store.getters.getPeriodoReporte[0]['hasta'])
    const listOfFormsRoutes = computed(() => {
      let tempFormsRoutes = router.options.routes.filter((current) => current.name == 'Formularios')[0]
        .children
      return tempFormsRoutes.map((current) => current.name)
    })

    return {
      route,
      periodoActualHasta,
      periodoActualDesde,
      listOfFormsRoutes,
    }
  },
}
</script>

<style lang="scss" scoped>
.card__body {
  padding: 1rem;
  background: #f3f3f3;
  border: 1px solid #cccccc;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
}

.card__title {
  font-family: Avenir;
  font-size: 1rem;
  font-weight: 800;
  line-height: 19px;
  margin-bottom: 0.5rem;
}

p {
  font-style: normal;
  font-weight: 500;
  font-size: 2rem;
  line-height: 44px;
  color: #333333;
  margin: 0;
}

a {
  font-size: 0.85rem;
  text-decoration: underline;
}

.in-between {
  margin-bottom: 2rem;
}
</style>
